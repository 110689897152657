import { UserDataType } from "../../../../context/types";
import { ProductFormT } from "../../../../types/productTypes";
import { deepEqual } from "../../../../utils/deepEqual";

/**
 * @description Compares the intital data and updated(watched)) data to fill in the timeline field with what values have changed
 * @param watchedData
 * @param initialData
 * @param user
 * @param isVariobelOnlyField
 * @returns An updated version of watchedData containing the now updated timeline field
 */
const prepareFormUpdatedValues = (
  watchedData: ProductFormT,
  initialData: ProductFormT | null,
  user: UserDataType | null,
  isVariobelOnlyField: boolean
) => {
  const updatedValues: Partial<ProductFormT> = {};
  const newTimelineEntriesText = [];

  // Compare watched data with initial data
  // Used to get the values that changed instead of sending whole form(watchedData);
  // Result is in: updatedValues, newTimelineEntriesText
  for (const key in watchedData) {
    // Stop the loop if initialData is not available
    if (!initialData) break;

    // Skip the "timelineEntries" key because it's not needed in this step
    if (key === "timelineEntries") continue;

    const updatedValue = watchedData[key];
    const initialValue = initialData[key];

    const newTimelineEntriesTextItem = {
      previous: initialValue,
      current: updatedValue,
    };

    const valuesAreEqual = deepEqual(updatedValue, initialValue);

    if (!valuesAreEqual) {
      updatedValues[key] = updatedValue;
      newTimelineEntriesText.push({ [key]: newTimelineEntriesTextItem });
    }
  }

  const now = new Date();
  const newTimelineEntries = {
    type: "update",
    time: now,
    admin: user!._id,
    text: JSON.stringify(newTimelineEntriesText),
  };

  // timelineEntries is not part of type 'ProductFormInputs'
  // it is instead part of the ProductT
  // it is gotten during data fetch in EDIT MODE
  // @ts-ignore
  const existingTimelineEntries = initialData?.timelineEntries;

  updatedValues["timelineEntries"] = [
    ...(existingTimelineEntries ? existingTimelineEntries : []),
    newTimelineEntries,
  ];

  return updatedValues;
};


export default prepareFormUpdatedValues