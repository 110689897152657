import yup from "../helpers/customYup";
import { TimelineEntriesType } from "./customTimelineTypes";
import { OrderStatusHistoryType } from "./orderTypes";

export type CustomerLayoutProps = {
  id: string | undefined;
};

export const countrySchema = yup.object().shape({
  code: yup.string().required(),
  label: yup.string().required(),
  phone: yup.string().required(),
});
export const addressSchema = yup.object().shape({
  address: yup.string().required(),
  city: yup.string().required(),
  postcode: yup.string().required(),
  country: countrySchema.required(),
});

export const companySchema = yup.object().shape({
  name: yup.string(),
  ico: yup.string(),
  dic: yup.string(),
  icdph: yup.string(),
});

export const customerSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  isWholesaleCustomer: yup.boolean(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .when("$editMode", ([editMode], schema) =>
      editMode ? schema : schema.required().min(5)
    ),
  phone: yup.string(),
  billingAddress: yup.array().of(addressSchema).required(),
  shippingAddress: yup.array().of(addressSchema),
  company: companySchema.notRequired(),
  gdprConsent: yup.boolean().notOneOf([false]).defined(),
  marketingConsent: yup.boolean(),
  isRegistered: yup.boolean(),
  isVerified: yup.boolean(),
  discount: yup.object().shape({
    value: yup.number(),
    prefix: yup.string(),
  }),
  role: yup.string(),
});

export interface countryData extends yup.InferType<typeof countrySchema> {}
export interface AddressData extends yup.InferType<typeof addressSchema> {}
export interface CompanyData extends yup.InferType<typeof companySchema> {}
export interface CustomerData extends yup.InferType<typeof customerSchema> {}

export interface Country extends countryData {
  _id: string;
}
export interface Address extends AddressData {
  _id: string;
  country: Country;
}
export interface Company extends CompanyData {
  _id: string;

  // billingAddress: Address[];
  // shippingAddress: Address[];
}
export interface Customer extends CustomerData {
  _id: string;
  gdprConsentDate: string;
  marketingConsentDate?: string;
  registerDate?: string;
  billingAddress: Address[];
  shippingAddress: Address[];
  company?: Company;
  timelineEntries: TimelineEntriesType[];
  confirmDate: string;
  status: "active" | "removed";
}

export interface CustomerTimelineEmailsType {
  box: string;
  color: string;
  date: string;
  from: string;
  subject: string;
  to: string;
}

export type CustomerTimelineDataType =
  | "orderEntry"
  | "status"
  | "email"
  | "orderCreated"
  | "customerEntry";
export type CustomerOrderHistoryistoryDataType = {
  id: string;
  orderNumber: string;
  data: OrderStatusHistoryType;
};
export type CustomerOrderCreateDataType = { id: string; orderNumber: string };
export type CustomerOrderManualEntriesDataType = {
  id: string;
  orderNumber: string;
  data: TimelineEntriesType;
};

export type CustomerTimelineStateType = {
  time: Date;
  type: CustomerTimelineDataType;
  data:
    | TimelineEntriesType
    | CustomerOrderHistoryistoryDataType
    | CustomerOrderManualEntriesDataType
    | CustomerTimelineEmailsType
    | CustomerOrderCreateDataType;
};

export interface CustomerTimelineItemType {
  data: CustomerTimelineStateType;
}
