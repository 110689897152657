// ** MUI Imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ** Custom Components Imports
import PageHeader from "../../../comps/@core/components/page-header";

// ** Components Imports
import RoleCards from "../../../comps/roles";

const AdminRolesScreen = () => {
  return (
    <Grid container spacing={6}>
      <PageHeader
        title={<Typography variant="h5"> User roles </Typography>}
        subtitle={
          <Typography variant="body2">
            A role provides access to sections and features of the app. The super admin specifies who has access to a role
          </Typography>
        }
      />
      <Grid item xs={12} sx={{ mb: 5 }}>
        <RoleCards />
      </Grid>
    </Grid>
  );
};

export default AdminRolesScreen;
