import DatePicker from "react-datepicker";
import { Card, Grid, Select , CardHeader, CardContent, FormControl, InputLabel, MenuItem, SelectChangeEvent, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { DatePickerWrapper } from "../../../styled/datePickerWrapper";
import DatePickerInput from "../../utilities/date-picker-input";
import { ICompany } from "../../../hooks/useCompanies";
import { DateType } from "../../../types/reactDatepickerTypes";

interface Props {
  t: any;
  companies: ICompany[];
  companyValue: string;
  handleSourceValue: (e: SelectChangeEvent) => void;
  statusValue: string;
  handleStatusValue: (e: SelectChangeEvent) => void;
  orderStatusesObj: {
    [key: string]: string;
  };
  endDateRange: DateType;
  startDateRange: DateType;
  handleDateRangeChange: (dates: any) => void;
  dates: Date[];
  setDates: Dispatch<SetStateAction<Date[]>>;
  searchValue: string;
  handleSearchFilter: (val: string) => void;

}

const OrderListFilters = (props: Props) => {
  const {
    t,
    companies,
    companyValue,
    handleSourceValue,
    statusValue,
    handleStatusValue,
    orderStatusesObj,
    endDateRange,
    startDateRange,
    handleDateRangeChange,
    dates, setDates,
    searchValue,
    handleSearchFilter
  } = props;

  return (
    <Card>
      <CardHeader title={t("Filter")} />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="order-source-select">{t("Company")}</InputLabel>

              <Select
                fullWidth
                value={companyValue}
                sx={{ mr: 4, mb: 2 }}
                label={t("Company")}
                onChange={handleSourceValue}
                labelId="order-source-select"
              >
                <MenuItem value="">None</MenuItem>
                {companies.map((company, i) => (
                  <MenuItem key={i} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="order-status-select">{t("Status")}</InputLabel>

              <Select
                fullWidth
                value={statusValue}
                sx={{ mr: 4, mb: 2 }}
                label={t("Status")}
                onChange={handleStatusValue}
                labelId="order-status-select"
              >
                <MenuItem value="">None</MenuItem>
                {Object.keys(orderStatusesObj).map((orderStatus, i) => (
                  <MenuItem
                    key={i}
                    value={orderStatus}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {orderStatus}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <DatePickerWrapper>
              <DatePicker
                isClearable
                selectsRange
                monthsShown={2}
                endDate={endDateRange}
                selected={startDateRange}
                startDate={startDateRange}
                shouldCloseOnSelect={false}
                id="date-range-picker-months"
                onChange={handleDateRangeChange}
                customInput={
                  <DatePickerInput
                    dates={dates}
                    setDates={setDates}
                    label={t("Dates (range)")}
                    end={endDateRange as number | Date}
                    start={startDateRange as number | Date}
                  />
                }
              />
            </DatePickerWrapper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={t("Search")}
              value={searchValue}
              onChange={(e) => handleSearchFilter(e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrderListFilters;
