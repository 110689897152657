import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
  ChangeEvent,
} from "react";

// ** MUI Imports
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TablePagination,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FormikHelpers } from "formik";

import { Avatar } from "../../avatar";
import { Chip } from "../../chip";
import AddTimelineEntryCard from "../../AddTimelineEntryCard";

// ** Icons Imports

import { sortByKey } from "../../../helpers/sortByKey";
import { getInitials } from "../../../helpers/get-initials";
import relativeTime from "../../../helpers/getRelativeTime";

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../store/apps/orderStatus";
import { fetchData as fetchAdminData } from "../../../store/apps/admin";
import { getOrder, updateOrder } from "../../../store/apps/order";

// Types
import {
  OrderStatusHistoryType,
  OrderType,
} from "../../../types/orderTypes";
import { AppDispatch, RootState } from "../../../store";
import { ThemeColor } from "../../@core/layouts/types";
import {
  ValuesType,
  timelineEntryType,
  TimelineEntriesType,
  TimelineEntryTypeKeyType,
} from "../../../types/customTimelineTypes";
import { Admin } from "../../../types/adminTypes";

import { useTranslation } from "react-i18next";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { useAuth } from "../../../hooks/useAuth";

interface Props {
  data: OrderType;
  setData: Dispatch<SetStateAction<OrderType | null>>;
}

const OrderPreviewTimeline = (props: Props) => {
  const { data, setData } = props;

  const theme = useTheme();
  const { user } = useAuth();

  const { createdAt, customer, timelineEntries } = data;
  const fullName = customer.firstName + " " + customer.lastName;
  let { orderStatusHistory } = data;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(100);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { orderStatus, admin: admins } = useSelector(
    (state: RootState) => state
  );
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (
    values: ValuesType,
    formikHelpers: FormikHelpers<ValuesType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const now = new Date();

    const entries = [
      ...timelineEntries,
      { ...values, time: now, admin: user!._id },
    ];

    dispatch(
      updateOrder({
        id: data._id,
        data: {
          timelineEntries: entries,
        },
      })
    )
      .unwrap()
      .then((res) => {
        showSuccessAlert(t("Order has been updated"));
        setSubmitting(false);
        resetForm();

        const orderID = data._id

        dispatch(getOrder(orderID)).then((res) => {
          const { __v, ...temp } = res.payload.order;

          setData(temp);
        });
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        showErrorAlert(t("Error during update! Check console"));
      });
  };

  // fetch all admins
  useEffect(() => {
    dispatch(fetchAdminData({ search: "" }));
  }, [dispatch]);

  // fetch order statuses
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const statuses: any = orderStatus.data.reduce(
    (accumulator, status) => ({
      ...accumulator,
      [status._id]: { color: status.colorCode, name: status.name },
    }),
    {}
  );

  type TimelineDataType = "manual" | "status";

  let timelineData: {
    time: Date;
    type: TimelineDataType;
    data: OrderStatusHistoryType | TimelineEntriesType;
  }[] = [];

  timelineEntries.forEach((entry) => {
    const _data = {
      time: entry.time,
      type: "manual" as TimelineDataType,
      data: entry,
    };

    timelineData.push(_data);
  });

  orderStatusHistory.forEach((status) => {
    const _data = {
      time: status.modifiedOn,
      type: "status" as TimelineDataType,
      data: status,
    };

    timelineData.push(_data);
  });

  timelineData = timelineData.sort(sortByKey("time", false));

  useEffect(() => {
    if (timelineData.length) {
      setPageCount(timelineData.length);
    }
  }, [timelineData.length]);

  const totalPages = timelineData.length;

  // + 1 due to first page needing to be 0 for mui component
  const currentPage = page + 1 > totalPages ? totalPages : page + 1;
  const paginationStartIndex = (currentPage - 1) * rowsPerPage;
  const paginationEndIndex = paginationStartIndex + rowsPerPage;

  return (
    <Card>
      <CardHeader title="Activity Timeline" />

      <CardContent sx={{ pt: `${theme.spacing(2.5)} !important` }}>
        <Timeline
          sx={{
            my: 0,
            py: 0,
            paddingLeft: 0,
            paddingRight: 0,
            "& .MuiTimelineItem-root": {
              width: "100%",
              "&:before": {
                display: "none",
              },
            },
          }}
        >
          <TimelineItem sx={{ minHeight: 150 }}>
            <TimelineSeparator sx={{ ml: "-10px" }}>
              <Avatar
                skin="light"
                sx={{
                  width: 40,
                  height: 40,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {getInitials(
                  `${user?.displayName}`
                )}
              </Avatar>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              sx={{
                mt: 0,
                mb: (theme) => `${theme.spacing(3)} !important`,
              }}
            >
              <Card sx={{ borderRadius: 0 }}>
                <AddTimelineEntryCard {...{ onSubmit }} />
              </Card>
            </TimelineContent>
          </TimelineItem>

          {/* TODO: abstarct to use the same timeline components
                    from customer timeline items
                    take a look at `ViewProductTimeline` to refactor manual entry
          */}
          {timelineData
            .slice(paginationStartIndex, paginationEndIndex)
            .map((data, i) => {
              if (data.type === "status") {
                const { modifiedOn, status } =
                  data.data as OrderStatusHistoryType;
                const chipColor = statuses[status as unknown as string]
                  ? statuses[status as unknown as string].color
                  : ("grey" as ThemeColor);

                return (
                  <TimelineItem>
                    <TimelineSeparator sx={{ px: "4px" }}>
                      <TimelineDot color={chipColor} />
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent
                      sx={{
                        mt: 0,
                        mb: (theme) => `0 !important`,
                      }}
                    >
                      <Box
                        sx={{
                          mb: 0,
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {statuses[status as unknown as string] && (
                          <Chip
                            skin="light"
                            size="small"
                            label={statuses[status as unknown as string].name}
                            color={chipColor}
                            sx={{
                              textTransform: "capitalize",
                              "& .MuiChip-label": { lineHeight: "18px" },
                            }}
                          />
                        )}

                        {!modifiedOn && (
                          <Typography
                            variant="caption"
                            sx={{ color: "text.disabled" }}
                          >
                            NA
                          </Typography>
                        )}

                        {modifiedOn && (
                          <Typography
                            variant="caption"
                            sx={{ color: "text.disabled" }}
                          >
                            {relativeTime(new Date(), new Date(modifiedOn))}
                          </Typography>
                        )}
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                );
              }

              if (data.type === "manual") {
                const { type, text, time, admin } =
                  data.data as TimelineEntriesType;

                const Icon =
                  timelineEntryType[type as TimelineEntryTypeKeyType];
                const currentAdmin = (admins.data as Admin[]).find(
                  (_admin) => _admin._id === admin
                );

                return (
                  <TimelineItem
                    sx={{
                      minHeight: "60px",
                    }}
                  >
                    <TimelineSeparator>
                      <Icon color="primary" fontSize="small" />
                      <TimelineConnector />
                    </TimelineSeparator>

                    <TimelineContent
                      sx={{
                        mt: 0,
                        pt: 0,
                        mb: (theme) => `${theme.spacing(3)} !important`,

                        "&:hover": {
                          cursor: "pointer",
                          "& .entry-user": {
                            display: "contents",
                            transition: "all 0.3s ease-in-out",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          // mb: 3,
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            mr: 2,
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                        >
                          Order <i>{type}</i> Entry
                        </Typography>

                        {!time && (
                          <Typography
                            variant="caption"
                            sx={{ color: "text.disabled" }}
                          >
                            NA
                          </Typography>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          {admin && currentAdmin && (
                            <Box
                              sx={{ display: "none" }}
                              className="entry-user"
                            >
                              {currentAdmin.avatar ? (
                                <Avatar
                                  alt="Profile Pic"
                                  src={currentAdmin.avatar}
                                  skin="light"
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    fontWeight: 600,
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  skin="light"
                                  sx={{
                                    width: 24,
                                    height: 24,
                                    fontWeight: 600,
                                    fontSize: "0.75rem",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {getInitials(currentAdmin.displayName)}
                                </Avatar>
                              )}
                              <Typography
                                variant="caption"
                                sx={{ color: "text.disabled" }}
                              >
                                {currentAdmin.displayName} created this{" "}
                              </Typography>
                            </Box>
                          )}
                          {time && (
                            <Typography
                              variant="caption"
                              sx={{ color: "text.disabled" }}
                            >
                              {relativeTime(new Date(), new Date(time))}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Typography
                        variant="body2"
                        sx={{ mb: 2, textTransform: "capitalize" }}
                      >
                        Text: {text}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              }

              return null;
            })}

          {/* only bottom item has minHeight = 0 */}
          <TimelineItem sx={{ minHeight: 0 }}>
            <TimelineSeparator sx={{ px: "4px" }}>
              <TimelineDot color="info" />
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              sx={{ mt: 0, mb: (theme) => `${theme.spacing(3)} !important` }}
            >
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ mr: 2, fontWeight: 600 }}>
                  Order <i>Created</i>
                </Typography>

                {!createdAt && (
                  <Typography variant="caption" sx={{ color: "text.disabled" }}>
                    NA
                  </Typography>
                )}

                {createdAt && (
                  <Typography variant="caption" sx={{ color: "text.disabled" }}>
                    {relativeTime(new Date(), new Date(createdAt))}
                  </Typography>
                )}
              </Box>

              <Typography variant="body2" sx={{ mb: 2 }}>
                Invoice hasn't been paid
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  skin="light"
                  sx={{ mr: 2.5, width: 24, height: 24, fontSize: "0.75rem" }}
                >
                  {getInitials(fullName)}
                </Avatar>

                <Typography
                  variant="body2"
                  sx={{ fontWeight: 600, fontSize: "0.875rem" }}
                >
                  {fullName}
                </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </CardContent>

      <TablePagination
        component="div"
        count={pageCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default OrderPreviewTimeline;
