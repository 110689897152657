import React from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ProductCategoryT } from "../../../types/product-category-type";
import { AnyAbility } from "@casl/ability";

interface CellType {
  row: ProductCategoryT;
}

interface Props {
  t: any;
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<ProductCategoryT | null>
  >;
  toggleDeleteOpen: () => void;
  toggleEditOpen: () => void;
  ability: AnyAbility;
}

const getCategoriesTableColumns = (
  props: Props
): GridColumns<ProductCategoryT> => {
  const { setSelectedCategory, toggleDeleteOpen, toggleEditOpen, t, ability } = props;

  const canUpdate = ability.can('update', 'product-category');
  const canDelete = ability.can('delete', 'product-category');

  const columns = [
    {
      minWidth: 100,
      flex: 1,
      field: "name",
      headerName: t("Name"),
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2" component={Link} to={`#`}>
          {row.name}
        </Typography>
      ),
    },
    {
      minWidth: 100,
      flex: 1,
      field: "unregisteredDiscount",
      headerName: t("Unregistered Discount"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.discount.unregisteredCustomerDiscount.value} ${row.discount.unregisteredCustomerDiscount.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "unregisteredMargin",
      headerName: t("Unregistered Margin"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.margin.unregisteredCustomerMargin.value} ${row.margin.unregisteredCustomerMargin.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "registeredDiscount",
      headerName: t("Registered Discount"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.discount.registeredCustomerDiscount.value} ${row.discount.registeredCustomerDiscount.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "registeredMargin",
      headerName: t("Registered Margin"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.margin.registeredCustomerMargin.value} ${row.margin.registeredCustomerMargin.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "wholesaleDiscount",
      headerName: t("Wholesale Discount"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.discount.wholesaleCustomerDiscount.value} ${row.discount.wholesaleCustomerDiscount.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      field: "wholesaleMargin",
      headerName: t("Wholesale Margin"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography variant="body2">
            {`${row.margin.wholesaleCustomerMargin.value} ${row.margin.wholesaleCustomerMargin.prefix}`}
          </Typography>
        );
      },
    },
    {
      minWidth: 100,
      flex: 1,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          disabled
        />,
        <GridActionsCellItem
          showInMenu
          sx={{
            "& .MuiListItemIcon-root": { minWidth: 0 },
            textTransform: "none",
          }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          disabled={!canUpdate}
          onClick={() => {
            setSelectedCategory(row);
            toggleEditOpen();
          }}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          disabled={!canDelete}
          onClick={() => {
            setSelectedCategory(row);
            toggleDeleteOpen();
          }}
        />,
      ],
    },
  ];

  return columns;
};

export default getCategoriesTableColumns;
