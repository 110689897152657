import React from "react";
import { Box, Card, CardContent, Checkbox, Divider, Grid, Typography } from "@mui/material";

import { Admin } from "../../../../types/adminTypes";
import {
  roleDetails,
  superadminRoleDetail,
} from "../../../../utils/acl/acl-role-details";

interface Props {
  t: any;
  admin: Admin;
}

const ViewInfo = (props: Props) => {
  const { admin } = props;

  const getSelectedRoleDetails = () => {
    if (admin.role === "superadmin") return superadminRoleDetail;

    return roleDetails[admin.role];
  };

  const selectedRoleDetails = getSelectedRoleDetails();

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
        }}
      >
        <Typography variant="h6">{admin.displayName}'s Permisions</Typography>

        <Grid container spacing={8}>
          {Object.keys(selectedRoleDetails).map((appSection) => {
            const appFeatures = Object.keys(selectedRoleDetails[appSection]);

            return (
              <Grid item container xs={12} spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      fontWeight: 600
                    }}
                  >
                    {appSection}
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item container xs={12} spacing={4}>
                  {appFeatures.map((feature) => {
                    const appPermissions = Object.keys(selectedRoleDetails[appSection][feature]);
                    const permissionValue = (value: string) => selectedRoleDetails[appSection][feature][value]

                    return (
                      <Grid item container xs={12} sm={6} md={4} spacing={1}>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              textTransform: "capitalize",
                            }}
                          >
                            {feature}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          {appPermissions.map((permission) => (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography sx={{ flex: 0.65 }}>Can {permission}</Typography>
                              <Checkbox checked={permissionValue(permission)} />
                            </Box>
                          ))}
                        </Grid>
                      </Grid>
                    );})}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ViewInfo;
