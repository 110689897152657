/**
 * Compares two values for deep equality.
 * @param a - The first value to compare.
 * @param b - The second value to compare.
 * @returns A boolean indicating whether the two values are deeply equal.
 * @author ChatGPT
 */

export function deepEqual(a: any, b: any): boolean {
  // Check for reference equality
  if (a === b) {
    return true;
  }

  // Check for null or undefined
  if (a == null || b == null) {
    return false;
  }

  // Check if both are Date objects
  if (a instanceof Date && b instanceof Date) {
    return a.getTime() === b.getTime();
  }

  // Check if both are Arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  // Check if both are Objects
  if (typeof a === 'object' && typeof b === 'object') {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    // Check if both have the same number of keys
    if (keysA.length !== keysB.length) {
      return false;
    }

    // Check if all keys and values are equal
    for (const key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }

    return true;
  }

  // Fallback for primitive types
  return false;
}
