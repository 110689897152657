import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  DialogContent,
  Grid,
  Button,
  LinearProgress,
  MenuItem,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  BaseProductCategoryT,
  BaseProductCategoryValidationSchema,
  ProductCategoryT,
} from "../../../types/product-category-type";
import { pricingPrefixList } from "../form";
import { useDispatch, useSelector } from "react-redux";
import {
  createProductCategory,
  getNonCatgorizedProducts,
  updateProductCategory,
} from "../../../store/apps/product-categories";
import { AppDispatch, RootState } from "../../../store";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductType } from "../../../types/productTypes";
import { updateProduct } from "../../../store/apps/product";
import { SectionDivider } from "../form/SectionDivider";

interface FormState extends BaseProductCategoryT {
  products: ProductType[];
}

const pricingPrefix = {
  value: 0,
  prefix: "%",
};

const initialValues = {
  name: "",
  parentCategory: null,
  discount: {
    registeredCustomerDiscount: pricingPrefix,
    unregisteredCustomerDiscount: pricingPrefix,
    wholesaleCustomerDiscount: pricingPrefix,
  },
  margin: {
    registeredCustomerMargin: pricingPrefix,
    unregisteredCustomerMargin: pricingPrefix,
    wholesaleCustomerMargin: pricingPrefix,
  },
  applyMarginToChildrenCategories: false,
} as FormState;

interface Props {
  open: boolean;
  handleClose: () => void;
  type: "edit" | "add";
  editID?: string;
  editableValues?: FormState;
}

const ProductCategoriesForm = (props: Props) => {
  const { open, handleClose, type, editID, editableValues } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const categories = useSelector(
    (state: RootState) => state.productCategories.data
  );
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  const defaultValues = useMemo(() => {
    if (type === "add") return initialValues;

    return editableValues;
  }, [editableValues, type]);

  const [nonCategorizedProducts, setNonCategorizedProducts] = useState<
    ProductType[]
  >([]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormState>({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(BaseProductCategoryValidationSchema),
  });

  // set form values
  // so that it better handles default value calculation
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const fetchNonCatgorizedProducts = useCallback(() => {
    dispatch(getNonCatgorizedProducts())
      .unwrap()
      .then((res: any) => {
        setNonCategorizedProducts(res.products);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchNonCatgorizedProducts();
  }, [fetchNonCatgorizedProducts]);

  const onSubmit = async (data: FormState) => {
    if (data.parentCategory === "") {
      data.parentCategory = null;
    }

    const handleFinish = () => {
      reset();
      handleClose();
    };

    const { products, ...formData } = data;

    const getNullCategoryProduct = () => {
      if (type !== "edit" || !editableValues) {
        return [];
      }

      // in edit mode, if previously selected products are removed
      // prepare that for null category assigning
      const nullProducts: ProductType[] = editableValues.products.filter(
        (editableProduct) => {
          return !products.some(
            (product) => product._id === editableProduct._id
          );
        }
      );

      return nullProducts;
    };

    const handleProductCategoryAssign = async (categoryID: string) => {
      const nullProducts = getNullCategoryProduct();

      products.forEach(async (product) => {
        await dispatch(
          updateProduct({
            id: product._id,
            data: {
              category: categoryID,
            },
          })
        );
      });

      nullProducts.forEach(async (product) => {
        await dispatch(
          updateProduct({
            id: product._id,
            data: {
              category: null,
            },
          })
        );
      });

      fetchNonCatgorizedProducts();
    };

    if (type === "add") {
      await dispatch(createProductCategory(formData))
        .unwrap()
        .then((res: any) => {
          const category = res.productCategory as ProductCategoryT;
          handleProductCategoryAssign(category._id);

          showSuccessAlert(t("Product Category added"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to add product category"));
        });

      handleFinish();
    }

    if (type === "edit" && editID) {
      await dispatch(updateProductCategory({ id: editID, data: formData }))
        .unwrap()
        .then((res: any) => {
          const { id } = res;
          handleProductCategoryAssign(id);

          showSuccessAlert(t("Product Category updated"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to update product category"));
        });

      handleFinish();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {type === "add" ? t("Add") : t("Edit")} {t("Category").toLowerCase()}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: (theme) => `${theme.spacing(2)} !important` }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="off"
                    type="text"
                    label={`${t("Category")} ${t("Name").toLowerCase()}`}
                    placeholder="text"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <SectionDivider caption={`${t("Unregistered")} ${t("Customer")}`} />
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="discount.unregisteredCustomerDiscount.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Discount type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="discount.unregisteredCustomerDiscount.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Discount")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="margin.unregisteredCustomerMargin.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Margin type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="margin.unregisteredCustomerMargin.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Margin")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            <SectionDivider caption={`${t("Registered")} ${t("Customer")}`} />
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="discount.registeredCustomerDiscount.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Discount type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="discount.registeredCustomerDiscount.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Discount")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="margin.registeredCustomerMargin.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Margin type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="margin.registeredCustomerMargin.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Margin")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            <SectionDivider caption={`${t("Wholesale")} ${t("Customer")}`} />
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="discount.wholesaleCustomerDiscount.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Discount type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="discount.wholesaleCustomerDiscount.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Discount")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="margin.wholesaleCustomerMargin.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Margin type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="margin.wholesaleCustomerMargin.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Margin")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="discount.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Discount type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="discount.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Discount")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={3}>
              <Grid item xs={4}>
                <Controller
                  name="margin.prefix"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      select
                      label={t("Margin type")}
                      fullWidth
                    >
                      {pricingPrefixList.map((prefix, index) => (
                        <MenuItem key={index} value={prefix}>
                          {prefix}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="margin.value"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      type="number"
                      label={t("Margin")}
                      fullWidth
                      required
                    />
                  )}
                />
              </Grid>
            </Grid> */}

            <Grid item xs={12}>
              <Controller
                name="applyMarginToChildrenCategories"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormControlLabel
                    label={t("Apply margin to children categories")}
                    control={<Checkbox checked={value} onChange={onChange} />}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="parentCategory"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={t("Parent Category")}
                    fullWidth
                  >
                    <MenuItem value="">None</MenuItem>
                    {categories.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="products"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    options={nonCategorizedProducts}
                    getOptionLabel={(option) => option.name}
                    {...field}
                    onChange={(e, data) => field.onChange(data)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Products")}
                        placeholder={t("Search")}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            <Grid item mt={2}>
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                type="submit"
              >
                {type === "add" ? t("Add") : t("Edit")}{" "}
                {t("Category").toLowerCase()}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProductCategoriesForm;
