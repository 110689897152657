import { AbilityBuilder, PureAbility } from '@casl/ability'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | 'email'

export type AppAbilityT = PureAbility<[Actions, Subjects]>
export const AppAbility = PureAbility

export type ACLObj = {
  action: Actions
  subject: string
}

export const supportedAdminRoles = ["superadmin", "admin", "editor", "user"] as const;
export type AdminRolesT = typeof supportedAdminRoles[number]; // returns enum

/**
 * Define permissions for a role.
 * This defines what pages/ui elements are accesssible.
 * Static Permissions for roles.
 */
const defineRulesFor = (role: AdminRolesT, subject: string) => {
  const { can, build } = new AbilityBuilder<AppAbilityT>(AppAbility);

  if (role === "superadmin") {
    can("manage", [subject, "all"]);
  } else if (role === "admin") {
    can(["create", "read", "update", "email"], subject);
  } else if (role === "editor") {
    can(["read", "update"], subject);
  } else if (role === "user") {
    can(["read"], subject);
  } else {
    can("read", subject);
  }

  return build;
};

export const buildAbilityFor = (role: AdminRolesT, subject: string): AppAbilityT => {
  const ability = defineRulesFor(role, subject)

  return ability({
    // See: https://casl.js.org/v6/en/guide/subject-type-detection#custom-subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type // mainly is string
  })
}

export const defaultACLObj: ACLObj = {
  action: 'read',
  subject: 'all'
}

// export default defineRulesFor
