import { useEffect, useState, SyntheticEvent, useMemo, useCallback, SetStateAction, Dispatch } from "react";

import {
  Grid,
  Tab as MuiTab,
  Icon,
  Box,
  TabProps,
  styled,
  SelectChangeEvent,
} from "@mui/material";

import { GridColumns } from "@mui/x-data-grid";

import { TabContext, TabList, TabPanel } from "@mui/lab";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import DialogContainer from "../../comps/dialogContainer";

import ViewProductOrders from "./viewTabs/viewProductOrders";
import ViewProductInfo from "./viewTabs/viewProductInfo";
import ViewProductLeft from "./viewTabs/viewProductLeft";
import ViewProductTimeline from "./viewTabs/viewProductTimeline";

import { getProduct } from "../../store/apps/product";
import useCompanies from "../../hooks/useCompanies";
import EditProductScreen from "./editProduct";
import { fetchProductOrders } from "../../store/apps/customer";
import { fetchData as fetchOrderStatus } from "../../store/apps/orderStatus";

import { AppDispatch, RootState } from "../../store";
import { ProductT, ProductType } from "../../types/productTypes";
import { DateType } from "../../types/reactDatepickerTypes";
import { OrderType } from "../../types/orderTypes";
import { useSettings } from "../../hooks/useSettings";
import getProductOrderListTableColumns from "../../comps/products/view/product-orders-list-table-columns";
import { isVariobelLineItem } from "../../comps/order/add-edit/helpers";

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const TABS = [
  {
    value: "info",
    label: "Info",
    icon: "description",
  },
  {
    value: "orders",
    label: "Orders",
    icon: "description",
  },
  {
    value: "timeline",
    label: "Timeline",
    icon: "description",
  },
];

const ViewProductScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [product, setProduct] = useState<ProductT>();
  const [tabValue, setTabValue] = useState<string>("info");
  const [editProductOpen, setEditProductOpen] = useState(false);
  const [startDateRange, setStartDateRange] = useState<DateType>(null);
  const [endDateRange, setEndDateRange] = useState<DateType>(null);
  const [dates, setDates] = useState<Date[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [tableData, setTableData] = useState<OrderType[]>([]);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [statusValue, setStatusValue] = useState<string>("");

  const { t } = useTranslation();
  const companies = useCompanies();
  const orderStatusesStore = useSelector(
    (state: RootState) => state.orderStatus.data
  );
  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const isVariobelOnlyField = configuredCompany === "variobel";

  const company = companies.find((c) => c.id === product?.company);

  const tabs = TABS;

  const handleOnChangeRange = (dates: any) => {
    const [start, end] = dates;
    if (start !== null && end !== null) {
      setDates(dates);
    }
    setStartDateRange(start);
    setEndDateRange(end);
  };

  const handleChange = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const handleStatusValue = (e: SelectChangeEvent) => {
    setStatusValue(e.target.value);
  };

  const toggleEditProductDialog = () => setEditProductOpen((prev) => !prev);

  // fetch order statuses
  useEffect(() => {
    dispatch(fetchOrderStatus());
  }, [dispatch]);

  // fetch product
  useEffect(() => {
    if (id) {
      (async () =>
        dispatch(getProduct(id)).then((res) => {
          const _product = res.payload.product;
          if ("saleEnds" in _product) {
            _product.saleEnds = _product.saleEnds
              ? new Date(_product.saleEnds)
              : null;
          }

          setProduct(_product as ProductT);
        }))();
    }
  }, [dispatch, id]);

  // fetch order table data for product
  useEffect(() => {
      setIsTableLoading(true);

      dispatch(
        fetchProductOrders({
          id: String(id),
          queries: {
            after: dates.length ? dates[0].toISOString() : "",
            before: dates.length ? dates[1].toISOString() : "",
            status: statusValue,
          },
        })
      )
        .unwrap()
        .then((res) => setTableData(res.orders))
        .catch((err) => console.log(err))
        .finally(() => setIsTableLoading(false));
  }, [dates, dispatch, id, statusValue]);

  // update totalPrice
  useEffect(() => {
    if (tableData.length) {
      const _price = tableData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.basketPrice.price,
        0
      );
      setTotalPrice(_price);
    }
  }, [tableData]);

  // update totalArea - (is variobel-only-field)
  useEffect(() => {
    if (!isVariobelOnlyField) return;

    if (tableData.length) {
      const _price = tableData.reduce((accumulator, currentValue) => {
        // type check
        // returns 0 - placeholder/dummy return - syntax rules of array.reduce
        // return isn't actually used
        // see fxn definition for more
        if(!isVariobelLineItem(currentValue.lineItems[0])) {
          return 0
        };

        return (
          accumulator +
          (currentValue.lineItems[0].glassWidth *
            currentValue.lineItems[0].glassHeight *
            currentValue.lineItems[0].qty) /
            Math.pow(10, 6)
        );
      }, 0);
      setTotalArea(_price);
    }
  }, [tableData, isVariobelOnlyField]);

  const handleCallbackOnStatusChange = useCallback(() => {
    dispatch(
      fetchProductOrders({
        id: String(id),
        queries: {
          after: dates.length ? dates[0].toISOString() : "",
          before: dates.length ? dates[1].toISOString() : "",
          status: statusValue,
        },
      })
    )
      .unwrap()
      .then((res) => setTableData(res.orders))
      .catch((err) => console.log(err));
  }, [dates, dispatch, id, statusValue]);

  const columns = useMemo<GridColumns<OrderType>>(
    () =>
      getProductOrderListTableColumns({
        t,
        handleCallbackOnStatusChange,
        orderStatusesStore,
      }),
    [t, orderStatusesStore, handleCallbackOnStatusChange]
  );

  if (!product) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={3}>
        <ViewProductLeft
          {...{
            product,
            company,
            toggleEditProductDialog,
            isVariobelOnlyField,
          }}
        />
      </Grid>

      {/* TABS */}
      <Grid item xs={12} md={9}>
        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="forced scroll tabs"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                value={tab.value}
                label={t(tab.label)}
                icon={<Icon sx={{ fontSize: "18px" }}>{tab.icon}</Icon>}
                sx={{
                  "& .MuiTab-iconWrapper": {
                    mb: 0,
                    mr: "0.25rem",
                  },
                }}
              />
            ))}
          </TabList>

          <Box mt={3}>
            <TabPanel sx={{ p: 0 }} value="info">
              <ViewProductInfo {...{ product, isVariobelOnlyField }} />
            </TabPanel>

            <TabPanel sx={{ p: 0 }} value="orders">
              <ViewProductOrders
                {...{
                  totalArea,
                  totalPrice,
                  startDateRange,
                  endDateRange,
                  handleOnChangeRange,
                  statusValue,
                  tableData,
                  dates,
                  setDates,
                  handleStatusValue,
                  isTableLoading,
                  columns,
                  pageSize,
                  setPageSize,
                }}
              />
            </TabPanel>

            <TabPanel sx={{ p: 0 }} value="timeline">
              <ViewProductTimeline
                {...{
                  id: String(id),
                  product: product as ProductType,
                  setProduct: setProduct as Dispatch<
                    SetStateAction<ProductType | undefined>
                  >,
                }}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>

      <DialogContainer
        open={editProductOpen}
        handleClose={toggleEditProductDialog}
      >
        <EditProductScreen
          id={product._id}
          handleClose={toggleEditProductDialog}
        />
      </DialogContainer>
    </Grid>
  );
};

export default ViewProductScreen;
