import { Grid, TextField, FormControl, InputLabel, MenuItem, Select, Autocomplete, createFilterOptions } from "@mui/material";
import { Control, FieldErrors, Controller, UseFormSetValue } from "react-hook-form";
import { PorezProductFormInputsT, ProductFormInputs, ProductFormT, porezProductConditions } from "../../../types/productTypes";
import { SectionDivider } from "./SectionDivider";
import FormFieldError from "./form-field-error";
import ProductImageFileUploader from '../product-image-file-uploader';

interface Props {
  control: Control<ProductFormInputs | PorezProductFormInputsT, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs | PorezProductFormInputsT>;
  setValue:  UseFormSetValue<ProductFormT>;
}

const PorezProductFields = ({ control, t, errors, setValue }: Props) => {
  const thicknessList = [10, 18]

  const filter = createFilterOptions<number>();

  return (
    <>
      <SectionDivider caption={t("Product Data")} />

      <Grid item container spacing={5}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="code"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  label={`${t("Code")}`}
                  onChange={onChange}
                  placeholder=""
                  error={Boolean(errors.code)}
                />
              )}
            />
            {errors.code && <FormFieldError />}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="surface"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  label={`${t("Surface")}`}
                  onChange={onChange}
                  placeholder=""
                  error={Boolean(errors.surface)}
                />
              )}
            />
            {errors.surface && <FormFieldError />}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="condition"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <FormControl>
                  <InputLabel>{t("Condition")}</InputLabel>
                  <Select
                    label={t("Condition")}
                    value={value}
                    onChange={onChange}
                  >
                    {porezProductConditions.map((condition, index) => (
                      <MenuItem key={index} value={condition}>
                        {t(condition)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.condition && <FormFieldError />}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Controller
              name="thickness"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <FormControl>
                  <Autocomplete
                    multiple
                    value={value}
                    onChange={(e, newValue) => {
                      setValue("thickness", newValue.flat())
                    }}
                    options={thicknessList}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      const isExisting = options.some((option) => option === Number(inputValue))
                      const isValueNaN = isNaN(Number(inputValue));

                      if(inputValue !== '' && !isExisting && !isValueNaN) {
                        // Allows selecting of custom option
                        filtered.push(Number(inputValue))
                      };

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                      {...params}
                      label={t("Thickness")}
                      error={Boolean(errors.thickness)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {errors.thickness && <FormFieldError />}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <Controller
              name="image"
              control={control}
              // rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  label={`${t("Image")}`}
                  onChange={onChange}
                  placeholder=""
                  error={Boolean(errors.image)}
                />
              )}
            />
            {errors.image && <FormFieldError />}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <ProductImageFileUploader setValue={setValue} />
        </Grid>
      </Grid>
    </>
  );};

export default PorezProductFields;
