import { ThemeColor } from "../../comps/@core/layouts/types";
import { AdminRolesT } from "../../configs/acl";

type FeatureT = {
  create: boolean,
  view: boolean,
  edit: boolean,
  delete: boolean
}

type AppFeaturesT = {
  customers: FeatureT,
  products: FeatureT,
  orders: FeatureT,
  email: {
    receive: boolean;
    send: boolean;
  }
}

type ShortAdminRolesT = Exclude<AdminRolesT, "superadmin">

type RoleDetailsT = {
  [K in ShortAdminRolesT]: {
    [key: string]: any;
    app: AppFeaturesT,
  }
};

type SRoleDetailsT = {
  [key: string]: any;

  app: AppFeaturesT,
  general: {
    "order status": FeatureT,
    translations: FeatureT,
    email: FeatureT,
    companies: FeatureT,
    users: FeatureT
  }
};

const superAdminPermission = {
  create: true,
  view: true,
  edit: true,
  delete: true
}

/***
 * Shows what a features a superadmin role has access to.
 * Actual access control is done in `src/configs/acl`
 */
export const superadminRoleDetail: SRoleDetailsT = {
  app: {
    customers: superAdminPermission,
    products: superAdminPermission,
    orders: superAdminPermission,
    email: {
      receive: true,
      send: true
    }
  },
  general: {
    "order status": superAdminPermission,
    translations: superAdminPermission,
    email: superAdminPermission,
    companies: superAdminPermission,
    users: superAdminPermission
  }
};

/***
 * Shows what a features a role has access to.
 * Actual access control is done in `src/configs/acl`
 */
export const roleDetails: RoleDetailsT = {
  admin: {
    app: {
      customers:{
        create: true,
        view: true,
        edit: true,
        delete: false
      },
      products:{
        create: true,
        view: true,
        edit: true,
        delete: false
      },
      orders:{
        create: true,
        view: true,
        edit: true,
        delete: false
      },
      email:{
        receive: true,
        send: true
      },
    },
  },

  editor: {
    app: {
      customers:{
        create: false,
        view: true,
        edit: true,
        delete: false
      },
      products:{
        create: false,
        view: true,
        edit: true,
        delete: false
      },
      orders:{
        create: false,
        view: true,
        edit: true,
        delete: false
      },
      email:{
        receive: true,
        send: true
      },
    }
  },

  user: {
    app: {
      customers:{
        create: false,
        view: true,
        edit: false,
        delete: false
      },
      products:{
        create: false,
        view: true,
        edit: false,
        delete: false
      },
      orders:{
        create: false,
        view: true,
        edit: false,
        delete: false
      },
      email:{
        receive: true,
        send: true
      },
    }
  }
};

type RoleThemeColorsT = {
  [K in AdminRolesT]: ThemeColor;
}

export const roleThemeColors: RoleThemeColorsT = {
  superadmin: "success",
  admin: "info",
  editor: "warning",
  user: "primary",
}